'use strict';

const SELECTORS = {
    body: 'body',
    navLink: '.js-tabs-nav .nav-link',
    slickSlider: '.js-slick-slider',
    tabsSelect: '.js-tabs-select',
};

/**
 * Trigger slider re-init on Tabs change
 */
function reInitSliders() {
    let $tabContent = $($(this).attr('href'));
    if ($tabContent && $tabContent.length > 0) {
        $tabContent.find(SELECTORS.slickSlider).slick('refresh');
    }
}

/**
 * Handle tab selection
 */
function handleTabSelect() {
    let $tabCta = $(`[href="${this.value}"]`);
    if ($tabCta && $tabCta.length > 0) {
        $tabCta.trigger('click');
    }
}

/**
 * Init all module related events here
 * @returns {void}
 */
const initEvents = () => {
    $(SELECTORS.navLink).on('click', reInitSliders);
    $(SELECTORS.tabsSelect).on('change', handleTabSelect);
}

module.exports = {
    initEvents: initEvents(),
}
